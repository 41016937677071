import ApiService from "@/core/services/api.service";

const END_POINT = "/logistics";
const USER_END_POINT = `${END_POINT}/users`;
const ADDRESSBOOK_END_POINT = `${END_POINT}/address-book`;

const getUsers = () => ApiService.get(`${USER_END_POINT}`);
const createUser = param => ApiService.post(`${USER_END_POINT}`, param);
const updateUser = (id, param) => ApiService.put(`${USER_END_POINT}/${id}`, param);
const lockUser = id => ApiService.put(`${USER_END_POINT}/${id}/lock`);
const unlockUser = id => ApiService.put(`${USER_END_POINT}/${id}/unlock`);

const listAddressbook = () => ApiService.query(`${ADDRESSBOOK_END_POINT}`);
const createAddressbook = param => ApiService.post(`${ADDRESSBOOK_END_POINT}`, param);
const updateAddressbook = (id, param) => ApiService.put(`${ADDRESSBOOK_END_POINT}/${id}`, param);
const deleteAddressbook = id => ApiService.delete(`${ADDRESSBOOK_END_POINT}/${id}`);

const getDashboard = () => ApiService.get(`${END_POINT}/dashboard`);

export {
  getUsers,
  createUser,
  updateUser,
  lockUser,
  unlockUser,
  listAddressbook,
  createAddressbook,
  updateAddressbook,
  deleteAddressbook,
  getDashboard
};
