<template>
  <div class="content d-flex flex-column flex-column-fluid">
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom gutter-b">
          <!--begin::Body-->
          <div class="card-body p-0">
            <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
              <div class="col-xl-12 col-xxl-7">
                <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                  <b-form @submit.prevent="handleSubmit(createAddressbookFunction)" autocomplete="off">
                    <h3 class="mb-10 font-weight-bold text-dark">
                      Address Book:
                    </h3>
                    <div class="row">
                      <div class="col-xl-12">
                        <b-form-group label-cols-sm="3" id="input-group-1" label="Company" label-for="input-1">
                          <b-form-input
                            v-model="company"
                            id="input-1"
                            type="text"
                            name="company"
                            class="form-control form-control-lg form-control-solid"
                          >
                          </b-form-input>
                        </b-form-group>

                        <validation-provider rules="required" name="address" v-slot="{ valid, errors }">
                          <b-form-group label-cols-sm="3" id="input-group-1" label="Address" label-for="input-1">
                            <b-form-input
                              v-model="address"
                              id="input-1"
                              type="text"
                              name="address"
                              class="form-control form-control-lg form-control-solid"
                              :state="errors[0] ? false : valid ? true : null"
                            >
                            </b-form-input>
                            <b-form-invalid-feedback id="inputLiveFeedback">
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <b-form-group label-cols-sm="3" id="input-group-1" label="Unit No." label-for="input-3">
                          <b-input-group size="lg">
                            <b-input-group-prepend>
                              <span class="input-group-text">
                                <i class="la la-hashtag"></i>
                              </span>
                            </b-input-group-prepend>
                            <b-form-input
                              v-model="unit_no"
                              id="input-3"
                              type="text"
                              class="form-control form-control-lg form-control-solid"
                              name="unit_no"
                            ></b-form-input>
                          </b-input-group>
                        </b-form-group>

                        <b-form-group label-cols-sm="3" id="input-group-1" label="Contact Person" label-for="input-3">
                          <b-input-group size="lg">
                            <b-input-group-prepend>
                              <span class="input-group-text">
                                <i class="la la-user"></i>
                              </span>
                            </b-input-group-prepend>
                            <b-form-input
                              v-model="contact_person"
                              id="input-3"
                              type="text"
                              class="form-control form-control-lg form-control-solid"
                              name="contact_person"
                            ></b-form-input>
                          </b-input-group>
                        </b-form-group>

                        <b-form-group label-cols-sm="3" id="input-group-1" label="Contact Number" label-for="input-3">
                          <b-input-group size="lg">
                            <b-input-group-prepend>
                              <span class="input-group-text">
                                <i class="la la-phone"></i>
                              </span>
                            </b-input-group-prepend>
                            <b-form-input
                              v-model="contact_number"
                              id="input-3"
                              type="text"
                              class="form-control form-control-lg form-control-solid"
                              name="contact_number"
                            ></b-form-input>
                          </b-input-group>
                        </b-form-group>

                        <b-form-group label-cols-sm="3" id="input-group-1" label="Remark" label-for="input-1">
                          <b-form-textarea
                            v-model="remark"
                            id="input-1"
                            type="text"
                            name="address"
                            class="form-control form-control-lg form-control-solid"
                          >
                          </b-form-textarea>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="d-flex justify-content-between border-top pt-10">
                      <submit-button :loading="loading" />
                    </div>
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createAddressbook } from "@/api/logistics.api";
import { useNotify } from "@/utils/notify";

import { ref } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  name: "createAddressBook",
  components: { ValidationProvider, ValidationObserver },
  setup(_, context) {
    const { notify } = useNotify(context);

    const company = ref("");
    const address = ref("");
    const unit_no = ref("");
    const contact_person = ref("");
    const contact_number = ref("");
    const remark = ref("");

    const loading = ref(false);

    const createAddressbookFunction = async function() {
      loading.value = true;
      const payload = {
        company: company.value,
        address: address.value,
        unit_no: unit_no.value,
        contact_person: contact_person.value,
        contact_number: contact_number.value,
        remark: remark.value
      };
      try {
        await createAddressbook(payload);
        notify("Address book created", `The address book is created`);
        context.root.$router.push({ name: "ListAddressbook" });
      } catch (e) {
        console.error(e);
      }

      loading.value = false;
    };

    return {
      company,
      address,
      unit_no,
      contact_person,
      contact_number,
      remark,

      createAddressbookFunction,
      loading
    };
  }
};
</script>
